import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavBarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  setFocus (id: string) {
    setTimeout(() => {
      var inputEl= document.getElementById(id);
      if (inputEl) {
        document.getElementById(id).focus();
      }
    }, 0);
  }

}

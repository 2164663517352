import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavService, Menu } from '../../services/nav.service';
import { UserService } from '../../services/firebase/user.service';
import { User } from '../../model/user.model';
import { EventEmitterService } from '../../services/event-emitter.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public menuItems: Menu[];
  public url: any;
  public fileurl: any;
  public user: User;

  constructor(private router: Router, 
    public navServices: NavService, 
    private userService: UserService,
    private eventEmitterService: EventEmitterService ) {

  }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem('user'));
    this.userService.getUser(user.uid).subscribe(data => {
      this.user = data as User;
      this.navServices.items.subscribe(menuItems => {
        this.menuItems = menuItems.filter(item =>{
          //for no roles defined
          if(!item.roles)
            return true;

          var hasRole = false;
          item.roles.forEach(role => {
            if(this.user.roles[role]){
              hasRole = true;
              return;
            }
          })
          return hasRole;
        });
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.menuItems.filter(items => {
              if (items.path === event.url && this.user.roles[items.roles[0]])
                this.setNavActive(items)
              if (!items.children) return false
              items.children.filter(subItems => {
                if (subItems.path === event.url)
                  this.setNavActive(subItems)
                if (!subItems.children) return false
                subItems.children.filter(subSubItems => {
                  if (subSubItems.path === event.url)
                    this.setNavActive(subSubItems)
                })
              })
            })
          }
        })
      })
  
    });
   }
  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }

  // Click Toggle menu
  toggletNavActive(item) {
    // if (!item.active) {
    //   this.menuItems.forEach(a => {
    //     if (this.menuItems.includes(item))
    //       a.active = false
    //     if (!a.children) return false
    //     a.children.forEach(b => {
    //       if (a.children.includes(item)) {
    //         b.active = false
    //       }
    //     })
    //   });
    // }
    item.active = !item.active
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

  openMobileNav() {
    this.eventEmitterService.toggleSideBar();
  }

}

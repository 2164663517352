<form [formGroup]="regForm" class="ngx-form" novalidate>
    <div class="form-group">
        <div class="row">
            <div class="col-lg-2">
                <label class="control-label" for="firstname">Upload Bill of Lading:</label>
                <br>
                <input style="display: none" type="file" (change)="fileChangeEvent($event)" #fileAttachmentInput>
                <button class="btn btn-outline-primary" (click)="fileAttachmentInput.click()"><i
                        class="fa fa-camera"></i>
                    Upload</button>
            </div>
            <div class="col-lg-2">
                <div class="text-center">
                    <img *ngIf='dropOffFileMeta === "image"' [src]="bolURL" width="125"
                        height="125" />
                </div>
            </div>
            <div class="col-lg-6">
            </div>
            <div class="col-lg" *ngIf='dropOffFileMeta === "pdf"'>
                <div class="text-center">
                    <pdf-viewer [src]="load.dropOff?.ticketPhotos[0].url" [render-text]="true" style="display: block;">
                    </pdf-viewer>
                </div>
            </div>
        </div>
    </div>
    <div style="align-items: center; justify-content: center;" class="loader-box col-lg-12" *ngIf='showLoader'>
        <div class="loader-box"><span class="rotate dotted"></span></div>
    </div>
    <div class="col-lg-12" [hidden]="!showCropper">
        <div class="card">
            <div class="card-header">
                <h5>Image Cropper</h5>
            </div>
            <div>
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false"
                    [imageBase64]="imageBase64" [roundCropper]="false" format="png" outputType="base64"
                    (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" #ImageCropper
                    [style.display]="showCropper ? null : 'none'"></image-cropper>
            </div>
            <div class="text-center">
                <img [src]="croppedImage" width="125" height="125" />
            </div>
            <div class="card-body btn-showcase text-center">
                <button class="btn btn-outline-primary" (click)="rotateLeft()">Rotate left</button>
                <button class="btn btn-outline-primary" (click)="rotateRight()">Rotate right</button>
                <button class="btn btn-outline-primary" (click)="flipHorizontal()">Flip horizontal</button>
                <button class="btn btn-outline-primary" (click)="flipVertical()">Flip vertical</button>
                <button class="btn btn-primary btn-block" (click)="savePicture()" type="button">Save</button>
            </div>
        </div>
    </div>
    <div class="form-group f1-buttons">
        <div class="form-group f1-buttons">
            <button class="btn btn-primary btn-previous" type="button" awPreviousStep>Previous</button>
            <button class="btn btn-success btn-next" (click)="save()" [disabled]="!regForm.valid" awNextStep>
                Complete</button>
        </div>
    </div>
</form>
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { User } from '../../model/user.model';
import { InjectWhoDataService } from '../inject-who-data.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(public db: AngularFirestore, 
    private storage: AngularFireStorage,
    private injectWhoData: InjectWhoDataService) { }

  /**
   * Upload user's profile photo
   * @param user 
   * @param photo 
   */
  uploadProfilePhoto(user: User, photo) {
    user = this.injectWhoData.injectWhoData(user);
    const file = this.b64toBlob(photo)
    const filePath = `ProfilePicture/${user.uid}`;
    const fileRef = this.storage.ref(filePath);
    this.storage.upload(`ProfilePicture/${user.uid}`, file).then(_ => {
      fileRef.getDownloadURL().subscribe(url => {
        user.photoURL = url;
        this.db.collection('users').doc(user.uid).set(user);
      })
    });
  }

  /**
   * Update user profiles info
   * @param user 
   */
  updateUser(user: User) {
    user = this.injectWhoData.injectWhoData(user);
    return this.db.collection('users').doc(user.uid).set(user);
  }

  /**
   * Create new User object
   * @param user 
   */
  createUser(user: User) {
    user = this.injectWhoData.injectWhoData(user);
    user.uid = this.db.createId();
    return this.db.collection('users').doc(user.uid).set(user);
  }

  /**
   * Search for user by email
   * @param searchValue 
   */
  searchUsers(searchValue) {
    return this.db.collection('users', ref => ref.where('email', '==', searchValue))
      .snapshotChanges();
  }

  /**
   * Get User by user id
   * @param userKey 
   */
  getUser(userKey) {
    return this.db.collection('users').doc(userKey).valueChanges();
  }

  /**
   * Get user profile pic 
   * @param userKey 
   */
  getUserPic(userKey) {
    return this.storage.storage.ref(`ProfilePicture/${userKey}`).getDownloadURL();
  }

  /**
   * Get all users
   */
  getUsers() {
    return this.db.collection('users').snapshotChanges();
  }

  /**
   * Delete user by user id
   * @param contactKey 
   */
  deleteUser(contactKey) {
    return this.db.collection('users').doc(contactKey).delete();
  }

  /**
   * Get Snake Highscores
   */
  getHighScores(){
    return this.db.collection('users', ref => ref.orderBy('snakeHighScore', 'desc').limit(5))
    .snapshotChanges();
  }

  /**
   * Conver bit map to BLOB for image cropper
   * @param dataURI 
   */
  b64toBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }
}

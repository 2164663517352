export interface CommodityRates {
    name?: string;
    rate?: number;
    uom?: string;
}

// Durum = BU. = 60 lbs./bu.
// Spring Wheat = BU = 60 lbs./bu.
// Corn = BU. = 56 lbs./bu.
// Soybeans = BU. = 60 lbs./bu.
// Yellow Peas = BU. = 60 lbs./bu.
// Green Peas = BU. = 60 lbs./bu.
// Flax = BU. = 56 lbs./bu.
// Barley = BU. = 48 lbs./bu.
// Oats = BU. = 32 lbs./bu.
// Rye = BU. = 56 lbs./bu.
// Sunflowers = CWT.
// Canola = CWT.
// Green Lentils = CWT.
// Red Lentils = CWT.
const DefaultRates: CommodityRates[] = [{
    name: 'Durum',
    rate: 60,
    uom: 'BU'
}, {
    name: 'Spring Wheat',
    rate: 60,
    uom: 'BU'
}, {
    name: 'Corn',
    rate: 56,
    uom: 'BU'
}, {
    name: 'Soybeans',
    rate: 60,
    uom: 'BU'
}, {
    name: 'Yellow Peas',
    rate: 60,
    uom: 'BU'
}, {
    name: 'Green Peas',
    rate: 60,
    uom: 'BU'
}, {
    name: 'Faba Beans',
    rate: 60,
    uom: 'BU'
}, {
    name: 'Chickpeas',
    rate: 60,
    uom: 'BU'
}, {
    name: 'Flax',
    rate: 56,
    uom: 'BU'
}, {
    name: 'Barley',
    rate: 48,
    uom: 'BU'
}, {
    name: 'Oats',
    rate: 32,
    uom: 'BU'
}, {
    name: 'Rye',
    rate: 56,
    uom: 'BU'
}, {
    name: 'Safflower',
    uom: 'CWT'
}, {
    name: 'Sunflowers',
    uom: 'CWT'
}, {
    name: 'Hi-Oleic Sunflowers',
    uom: 'CWT'
}, {
    name: 'NuSun Sunflowers',
    uom: 'CWT'
},{
    name: 'Canola',
    uom: 'CWT'
}, {
    name: 'Millet',
    uom: 'CWT'
}, {
    name: 'Green Lentils',
    rate: 60,
    uom: 'BU'
}, {
    name: 'Red Lentils',
    uom: 'CWT'
}, {
    name: 'Fertilizer',
    rate: 2000,
    uom: 'Ton'
},]

export class Rates {
    public defaultRates: CommodityRates[];
    constructor() {
        this.defaultRates = DefaultRates;
    }
}
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommodityRates } from 'src/app/shared/model/commodity-rates.model';
import { Load } from 'src/app/shared/model/load.model';
import { User } from 'src/app/shared/model/user.model';
import { ContractService } from 'src/app/shared/services/firebase/contract.service';
import { LoadService } from 'src/app/shared/services/firebase/load.service';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { Contract } from 'src/app/shared/model/contract.model';

@Component({
  selector: 'app-qty',
  templateUrl: './qty.component.html',
  styleUrls: ['./qty.component.scss']
})
export class QtyComponent implements OnInit {

  public border_validation = false;
  public regForm: FormGroup;
  public title: string = "registration page"
  public form: any;
  private user: User;
  public rates: CommodityRates[];

  public load: Load = { dropOff: {} };

  constructor(private route: ActivatedRoute,
    private fb: FormBuilder,
    public toster: ToastrService,
    public router: Router,
    private contractService: ContractService,
    private loadService: LoadService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.route.params.subscribe(params => {
      if (params['loadId']) {
        this.loadService.getLoadById(params['loadId']).subscribe(data => {
          this.load = data;
          if (!this.load.dropOff)
            this.load.dropOff = { qty: '' };
        })
      }
    });
  }

  //create form
  createForm() {
    this.regForm = this.fb.group({
      qty: [null, [Validators.required]]
    });
  }

  public inputValidator(event: any) {
    //console.log(event.target.value);
    const pattern = /^[0-9]*$/;
    //let inputChar = String.fromCharCode(event.charCode)
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
      // invalid character, prevent input

    }
  }

  complete() {
    if (this.load.status === 'Complete')
      this.router.navigate(['/office/loads']);
    else {
      this.router.navigate(['/contractor/my-loads']);
      this.load.dropOff.droppedOffBy = this.user.displayName;
      this.load.dropOff.droppedOffOn = moment().format('llll');
    }

    this.load.status = 'Complete';
    if (this.load.contractId !== '') {
      this.contractService.getContract(this.load.contractId).pipe(take(1)).subscribe(data => {

        let contract = data as Contract;

        //create allocation data on Load
        this.load.allocatedContracts = [{
          contractId: this.load.contractId,
          contractNo: contract.contractNo,
          qty: parseInt(this.load.dropOff.qty)
        }];

        this.load.allocatedContractIds = [
          this.load.contractId
        ];

        this.loadService.updateLoad(this.load).then(_ => {
          this.toster.success('Drop Off Completed!');

          let rate = Object.create(this.rates.find(rate => contract.commodity === rate.name));
          //if it's not per BU we assume Hundredweight which is 100lb per commodity
          if (contract.qtyUom != 'BU')
            rate.rate = 100;

          contract.grossWeight = rate.rate * contract.qty;

          this.getContractAllocations(contract).then((contractAllocation: number) => {
            contract.precentCompleted = Math.round((contractAllocation / (parseInt((contract.qty + '').replace(/[^0-9.]/g, '')) * rate.rate)) * 100);

            if (contract.precentCompleted >= 95)
              contract.status = "Complete";

            this.contractService.updateContract(contract).catch(data => {
              this.toster.error('There was an error updating the parent contract')
            });
          });

        });
      });
    } else {
      this.loadService.updateLoad(this.load).then(_ => {
        this.toster.success('Drop Off Completed!');
      });
    }

  }

  private getContractAllocations(contract: Contract) {
    return new Promise(resolve => {
      this.contractService.getAllocatedLoads(contract).subscribe(data => {
        const allocatedLoads = data.map(data => {
          let load = data.payload.doc.data() as Load;
          //this makes it so the only allocations for the load are the ones for the selected contract
          load.allocatedContracts = [load.allocatedContracts.find(data => {
            return data.contractId === contract.uid;
          })];

          return load;
        });
        let qtySum = 0;

        allocatedLoads.forEach(load => {
          qtySum += parseInt(load.allocatedContracts[0].qty + '');
        })
        resolve(qtySum);
      }, e => {
        resolve(0)
      })
    });
  }

  save(form: any) {
    if (!form.valid) {
      return false;
    }
    this.loadService.updateLoad(this.load);
    return true;
  }

}

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Finalize Load</h5>
                </div>
                <div class="card-body step-indicator">
                    <aw-wizard navBarLayout="large-filled-symbols">
                        <aw-wizard-step [navigationSymbol]="{ symbol: '&#xf007;', fontFamily: 'FontAwesome ' }"
                            stepTitle="Loaded By"
                            (stepEnter)="setFocus('firstName')">
                            <app-loaded-by></app-loaded-by>
                        </aw-wizard-step>
                        <aw-wizard-step [navigationSymbol]="{ symbol: '&#xf1b3;', fontFamily: 'FontAwesome' }"
                            stepTitle="Weight"
                            (stepEnter)="setFocus('weight')">
                            <app-weight></app-weight>
                        </aw-wizard-step>
                        <aw-wizard-step [navigationSymbol]="{ symbol: '&#xf249;', fontFamily: 'FontAwesome' }"
                            stepTitle="Notes"
                            (stepEnter)="setFocus('comments')">
                            <app-comments></app-comments>
                        </aw-wizard-step>
                        <aw-wizard-step [navigationSymbol]="{ symbol: '&#xf058;', fontFamily: 'FontAwesome' }"
                            stepTitle="Sign Off"
                            (stepEnter)="setFocus('signOffName')">
                            <app-sign-off></app-sign-off>
                        </aw-wizard-step>
                    </aw-wizard>
                </div>
            </div>
        </div>
    </div>
</div>
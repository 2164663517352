<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Dropoff Load</h5>
                </div>
                <div class="card-body step-indicator">
                    <aw-wizard navBarLayout="large-filled-symbols">
                        <aw-wizard-step [navigationSymbol]="{ symbol: '&#xf030;', fontFamily: 'FontAwesome ' }"
                            stepTitle="Capture Scale Ticket" (stepEnter)="setFocus('firstName')">
                            <app-scale-pic></app-scale-pic>
                        </aw-wizard-step>
                        <aw-wizard-step [navigationSymbol]="{ symbol: '&#xf1b3;', fontFamily: 'FontAwesome' }"
                            stepTitle="Qty Delivered" (stepEnter)="setFocus('qty')">
                            <app-qty></app-qty>
                        </aw-wizard-step>
                        <aw-wizard-step [navigationSymbol]="{ symbol: '&#xf1b3;', fontFamily: 'FontAwesome' }"
                            stepTitle="Bol"
                            *ngIf='!load?.isDestinationEBOL'>
                            <app-bol></app-bol>
                        </aw-wizard-step>
                    </aw-wizard>
                </div>
            </div>
        </div>
    </div>
</div>
<form [formGroup]="regForm" (ngSubmit)="save(regForm)" class="ngx-form" novalidate>
    <div class="form-group">
        <label class="control-label" for="weight">Gross Pounds:</label>
        <input id="weight" type="text" [(ngModel)]="load.bol.weight" formControlName="weight" class="form-control" placeholder="Enter Gross Pounds.." autofocus >
        <div *ngIf="regForm.controls['weight'].invalid && (regForm.controls['weight'].dirty || regForm.controls['weight'].touched)"
            class="alert">
            <div *ngIf="regForm.controls['weight'].errors.required">
                Weight is required.
            </div>
        </div>
    </div>
    <div class="form-group f1-buttons">
        <button class="btn btn-primary btn-previous" type="button" awPreviousStep>Previous</button>
        <button class="btn btn-primary btn-next" [disabled]="!regForm.valid" awNextStep> Next</button>
    </div>
</form>

import { Injectable, OnInit, NgZone, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from './user.service';
import { Roles } from '../../model/roles.model';

export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
  disabled?: boolean;
  roles?: Roles;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  public userData: any;
  public user: User;
  public showLoader: boolean = false;

  constructor(public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    public toster: ToastrService,
    private cookieService: CookieService,
    private userService: UserService) {

    this.afAuth.auth.setPersistence('session');

    this.afAuth.authState.subscribe(user => {
      //console.log(user);
      if (user) {
        this.userService.getUser(user.uid).subscribe(data => {
          this.userData = data as User;
          //Force user creation to fail if user is not approved
          if(!this.userData.roles || this.user?.disabled)
            return;

          cookieService.set('user', JSON.stringify(this.userData), 1, null, null, false, "Lax");
          JSON.parse(cookieService.get('user'));
          localStorage.setItem('user', JSON.stringify(this.userData));
          JSON.parse(localStorage.getItem('user'));
          if (this.router.routerState.snapshot.url == '/auth/login')
            this.router.navigate(['']);
        });
      } else {
        this.SignOut();
      }
    });
  }

  ngOnInit(): void { }

  //sign in function
  SignIn(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        if (result.user.emailVerified !== true) {
          //this.SetUserData(result.user);
          //this.SendVerificationMail();
        } else {
          this.showLoader = false;
        }
      }).catch((error) => {
        this.toster.error('You have enter the wrong Email or Password.');
      })
  }
  //main verification function
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification();
  }

  ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        this.toster.success('Password reset email sent, check your inbox.');
      }).catch((error) => {
        this.toster.error(error);
      });
  }

  // Sign up with email/password
  SignUp(email: string, password: string) {
    return this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.SetUserData(result.user).then(() => {
          this.SignOut();
        });
        this.toster.success('Account registered! Halo will review your account and will notify you once it is approved.');
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }


  //Authentication for Login
  AuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['/users/edit']);
        });
        //this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error);
      });
  }

  //Set user
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      email: user.email,
      displayName: user.displayName,
      uid: user.uid,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    };

    userRef.delete();

    return userRef.set(userData, {
      merge: true
    });
  }

  // Sign out
  SignOut() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    return this.afAuth.auth.signOut().then(() => {
      this.showLoader = false;
      localStorage.clear();
      this.cookieService.deleteAll('user', '/auth/login');
      this.router.navigate(['/auth/login']);
    });
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user != null) ? true : false;
  }

}
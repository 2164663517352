<form [formGroup]="regForm" (ngSubmit)="save(regForm)" class="ngx-form" novalidate>
    <div class="form-group">
        <label class="control-label" for="firstname">Name:</label>
        <input id="firstName" [(ngModel)]="load.bol.loadedBy" type="text" formControlName="firstName" class="form-control" placeholder="Enter Name..">
        <div *ngIf="regForm.controls['firstName'].invalid && (regForm.controls['firstName'].dirty || regForm.controls['firstName'].touched)"
            class="alert">
            <div *ngIf="regForm.controls['firstName'].errors.required">
                First Name is required.
            </div>
        </div>
    </div>
    <div class="form-group f1-buttons">
        <button class="btn btn-primary btn-next" [disabled]="!regForm.valid" awNextStep> Next
        </button>
    </div>
</form>
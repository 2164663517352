import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ContentLayoutComponent } from './components/layout/content-layout/content-layout.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ToastrModule } from 'ngx-toastr';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { NgSelectModule } from '@ng-select/ng-select';
import { PdfViewerModule } from 'ng2-pdf-viewer';

// services
import { NavService } from "./services/nav.service";
import { EventEmitterService } from './services/event-emitter.service';
import { CustomizerService } from "./services/customizer.service";

// Directives
import { ToggleFullscreenDirective } from "./directives/fullscreen.directive";
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { NavBarComponent } from './components/bol-wizard/nav-bar/nav-bar.component';
import { NavBarDropoffComponent } from './components/complete-load-wizard/nav-bar/nav-bar.component';
import { LoadedByComponent } from './components/bol-wizard/loaded-by/loaded-by.component';
import { WeightComponent } from './components/bol-wizard/weight/weight.component';
import { CommentsComponent } from './components/bol-wizard/comments/comments.component';
import { SignOffComponent } from './components/bol-wizard/sign-off/sign-off.component';
import { AutoLogoutService } from './services/auto-logout.service';
import { ScalePicComponent } from './components/complete-load-wizard/scale-pic/scale-pic.component';
import { QtyComponent } from './components/complete-load-wizard/qty/qty.component';
import { ImageCropperComponent } from './components/image-cropper/component/image-cropper.component';
import { BolComponent } from './components/complete-load-wizard/bol/bol.component';
import { CommentsNotesComponent } from './components/complete-load-wizard/comments-notes/comments-notes.component';

@NgModule({
  declarations: [
    HeaderComponent,
    ImageCropperComponent,
    FooterComponent,
    SidebarComponent,
    ContentLayoutComponent,
    FeatherIconsComponent,
    BreadcrumbComponent,
    ToggleFullscreenDirective,
    PhoneMaskDirective,
    NavBarComponent,
    NavBarDropoffComponent,
    LoadedByComponent,
    WeightComponent,
    CommentsComponent,
    SignOffComponent,
    ScalePicComponent,
    QtyComponent,
    BolComponent,
    CommentsNotesComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ToastrModule.forRoot(),
    GalleryModule.forRoot(),
    ArchwizardModule,
    ReactiveFormsModule,
    PdfViewerModule,
    NgSelectModule
  ],
  exports: [
    FeatherIconsComponent,
    PhoneMaskDirective,
    NavBarComponent,
    NavBarDropoffComponent,
    LoadedByComponent,
    WeightComponent,
    CommentsComponent,
    SignOffComponent,
    ImageCropperComponent
  ],
  providers: [
    NavService,
    EventEmitterService,
    AutoLogoutService,
    CustomizerService
  ]
})
export class SharedModule { }


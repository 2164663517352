import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Contract } from '../../model/contract.model';
import { InjectWhoDataService } from '../inject-who-data.service';
import { combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  constructor(public db: AngularFirestore,
    private storage: AngularFireStorage,
    private injectWhoData: InjectWhoDataService) { }

  /**
   * Creates a new Producer Contract
   * @param contract contract object
   * @param fileEvent The file for the contract
   */
  createContract(contract: Contract) {
    //get UID for contract and file
    contract.uid = this.db.createId();
    contract.contractFiles.push(contract.uid);
    contract = this.injectWhoData.injectWhoData(contract);

    return this.db.collection('contracts').doc(contract.uid).set(contract);
  }

  /**
   * Update contract
   * @param contract 
   * @param fileEvent 
   */
  updateContract(contract: Contract) {
    contract = this.injectWhoData.injectWhoData(contract);
    return this.db.collection('contracts').doc(contract.uid).update(contract);
  }

  /**
   * Attach files to contracts
   * @param event 
   * @param contract 
   */
  attachFile(event, contract) {
    contract = this.injectWhoData.injectWhoData(contract);
    var fileObj = { uid: this.db.createId(), url: '', name: '' };

    const file = event.target.files[0];
    const fileName = event.target.files[0].name;
    const filePath = `ContractAttachments/${fileObj.uid}`;
    const fileRef = this.storage.ref(filePath);

    return this.storage.upload(`ContractAttachments/${fileObj.uid}`, file).then(_ => {
      fileRef.getDownloadURL().subscribe(url => {
        if (!contract.attachmentFiles)
          contract.attachmentFiles = [];

        fileObj.url = url;
        fileObj.name = fileName;
        contract.attachmentFiles = [...contract.attachmentFiles, fileObj];

        this.db.collection('contracts').doc(contract.uid).set(contract);
      })
    });

  }

  /**
   * delete attachemnt from storage
   * @param attachment 
   */
  deleteAttachment(attachment) {
    this.storage.ref(`ContractAttachments/${attachment.uid}`).delete();
  }

  getAllocatedLoads(contract: Contract) {
    return this.db.collection('loads', ref => ref.where('allocatedContractIds', 'array-contains', contract.uid)).snapshotChanges();
  }

  /**
   * Get the next contract number
   */
  getNewContractNumber() {
    return this.db.collection('contracts', ref => ref.orderBy('contractNo', 'desc').limit(1)).valueChanges();
  }

  /**
   * Get contract files
   * @param contract 
   */
  getContractFiles(contract: Contract) {
    return this.storage.storage.ref(`Contracts/${contract.contractFiles[0]}.pdf`).getDownloadURL();
  }

  /**
  * Gets all the contracts
  */
  getContracts(statuses: string[] = ['New', 'Signed', 'Viewed', 'Complete', 'Closed'], isLogisticsContract: boolean = false) {
    if (isLogisticsContract)
      return this.db.collection('contracts', ref => ref
        .where('status', 'in', statuses)
        .where('logisticId', '!=', '')
      ).snapshotChanges();
    else
      return this.db.collection('contracts', ref => ref
        .where('status', 'in', statuses)
        .where('producerId', '!=', '')
      ).snapshotChanges();
  }

  /**
  * Gets all the contracts
  */
  getAllContracts(statuses: string[] = ['New', 'Signed', 'Viewed', 'Complete', 'Closed'], isLogisticsContract: boolean = false) {
    return this.db.collection('contracts', ref => ref
      .where('status', 'in', statuses)
    ).snapshotChanges();
  }

  /**
   * Gets all not closed contracts
   */
  getOpenContracts(isLogisticsContract: boolean = false) {
    if (isLogisticsContract)
      return this.db.collection('contracts', ref => ref
        .where('status', 'in', ['New', 'Viewed', 'Signed', 'Complete'])
        .where('logisticId', '!=', '')
      ).snapshotChanges();
    else
      return this.db.collection('contracts', ref => ref
        .where('status', 'in', ['New', 'Viewed', 'Signed', 'Complete'])
        .where('producerId', '!=', '')
      ).snapshotChanges();
  }

  /**
    * Gets all signed contracts
    */
  getSignedContracts(isLogisticsContract: boolean = false) {
    if (isLogisticsContract)
      return this.db.collection('contracts', ref => ref
        .where('status', 'in', ['Signed'])
        .where('logisticId', '!=', '')
      ).snapshotChanges();
    else
      return this.db.collection('contracts', ref => ref
        .where('status', 'in', ['Signed'])
        .where('producerId', '!=', '')
      ).snapshotChanges();
  }

  /**
   * Get's all of a specified producers contracts
   * @param producerId 
   * @returns 
   */
  getContractsByProducer(producerId: string) {
    return this.db.collection('contracts', ref => ref.where('producerId', '==', producerId)).snapshotChanges();
  }

  /**
   * Get's all of a specified producers contracts
   * @param logisticId 
   * @returns 
   */
  getContractsByLogisticCustomer(logisticId: string) {
    return this.db.collection('contracts', ref => ref.where('logisticId', '==', logisticId)).snapshotChanges();
  }

  /**
   * Get's all of a specified users contracts
   * @param userId 
   */
  getContractsByUser(userId: string) {
    return this.db.collection('contracts', ref => ref.where('producerUserId', '==', userId)).snapshotChanges();
  }

  /**
 * Get's all of a specified users contracts
 * @param userId 
 */
  getContractsByLogisticsUser(userId: string) {
    return this.db.collection('contracts', ref =>
      ref.where('logisticUserId', '==', userId)
    ).snapshotChanges();

  }

  /**
   * Get's all of a specified users contracts
   * @param producerUserId 
   */
  getContractsByProducerUser(producerUserId: string) {
    return this.db.collection('contracts', ref => ref.where('producerUserId', '==', producerUserId)).snapshotChanges();
  }

  /**
   * Get's all of a specified users contracts
   * @param logisticUserId 
   */
  getContractsByLogisticsCustomerUser(logisticUserId: string) {
    return this.db.collection('contracts', ref => ref.where('logisticUserId', '==', logisticUserId)).snapshotChanges();
  }

  /**
   * Update Contract with signature
   * @param contract 
   */
  signContract(contract: Contract) {
    contract = this.injectWhoData.injectWhoData(contract);
    return this.db.collection('contracts').doc(contract.uid).update(contract);
  }

  /**
   * Gets specific contract
   * @param uid 
   */
  getContract(uid: string) {
    return this.db.collection('contracts').doc(uid).valueChanges();
  }

  /**
   * Get's all loads tied to a contract
   * @param contractId 
   * @returns 
   */
  getLoadsByContract(contractId: string) {
    return this.db.collection('loads', ref => ref.where('contractId', '==', contractId)).snapshotChanges();
  }

  /**
   * deletes a contract
   */
  deleteContract(uid: string) {
    return this.db.collection('contracts').doc(uid).delete();
  }

  /**
   * Get all contracts that are not in new status
   */
  getNotNewContracts() {
    return this.db.collection('contracts', ref => ref.where('status', 'in', ['Signed', 'Assigned', 'Closed'])).snapshotChanges();
  }
}

<div class="page-wrapper">
	<div class="auth-bg">
		<div class="authentication-box">
			<div class="text-center"><img src="assets/images/logo/Halo-Logo2.png" alt=""></div>
			<div class="card mt-4" *ngIf="!contactView" [@inOutAnimation]>
				<div class="card-body">
					<div class="text-center" *ngIf="!contactView">
						<h4>{{this.restPassword? 'Reset Password':'Login'}}</h4>
						<h6>{{this.restPassword? 'Enter your Email':'Enter your Username and Password'}}</h6>
					</div>
					<form class="theme-form" [formGroup]="loginForm" *ngIf="!contactView">
						<div class="form-group">
							<label class="col-form-label pt-0">Email</label>
							<input class="form-control" placeholder="test@example.com" formControlName="email"
								type="email" required="">
							<div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
								class="text text-danger mt-1">
								Email is required
							</div>
							<div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
								class="text text-danger mt-1">
								Invalid Email
							</div>
						</div>
						<div class="form-group" *ngIf='!restPassword'>
							<label class="col-form-label">Password</label>
							<input *ngIf='restPassword || newUser' class="form-control" type="password" formControlName="password" minLength="8" required="">
							<input *ngIf='!restPassword && !newUser' class="form-control" type="password" formControlName="password" required="">
							<div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
								class="text text-danger mt-1">
								Password is required
							</div>
							<div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.minlength"
								class="text text-danger mt-1">
								Password must be at least 8 characters.
							</div>
						</div>
						<div class="form-group" *ngIf='newUser'>
							<label class="col-form-label">Confirm Password</label>
							<input class="form-control" type="password" formControlName="confirmPassword" required="">
							<div *ngIf="loginForm.controls.confirmPassword.touched && loginForm.controls.confirmPassword.errors?.required"
								class="text text-danger mt-1">
								Password Confirmation is required
							</div>
							<div *ngIf="loginForm.controls.confirmPassword.touched && loginForm.controls.confirmPassword.errors?.confirmedValidator"
								class="text text-danger mt-1">
								Passwords must be match.</div>
						</div>
						<div class="checkbox p-0" *ngIf='!restPassword && !newUser'>
							<a class="forgot-password" (click)="forgotPassword()">Forgot Password?</a>
							<br>
							<a class="forgot-password" (click)="newUserSelected()"> New User?</a>
							<a class="forgot-password pull-right" (click)="contactUs()">Contact Us &#8594;</a>
						</div>
						<div class="form-group form-row mt-2 mb-2" *ngIf='restPassword || newUser'>
							<button class="btn btn-primary mr-1" [class.loader--text]="authService.showLoader"
								(click)="login()" type="submit"><span>{{ newUser ? 'Register' : 'Reset Password'
									}}</span>
							</button>
							<button class="btn btn-light mr-1" [class.loader--text]="authService.showLoader"
								(click)="cancelForm()" type="submit"><span>Cancel</span>
							</button>
						</div>
						<div class="form-group form-row mt-3 mb-0" *ngIf='!restPassword && !newUser'>
							<button class="btn btn-primary btn-block btn-lg"
								[class.loader--text]="authService.showLoader"
								[disabled]="!loginForm.valid || authService.showLoader" (click)="login()"
								type="submit"><span>{{ authService.showLoader ? '' : 'Login' }}</span>
							</button>
						</div>
					</form>
				</div>
			</div>
			<div class="card mt-4" *ngIf="contactView" [@outInAnimation]>
				<div class="card-body">
					<div>
						<div class="col">
							<div class="row">
								<a class="forgot-password pull-right" (click)="contactUs()">&#8592; Back</a>
							</div>
							<div class="row m-t-20">
								<h4>
									Halo Commodity Company, LLC
								</h4>
							</div>
							<div class="row m-t-10">
								<h5>
									<u>About</u>
								</h5>
							</div>
							<div class="row">
								<p>
									Cash grain trading and grain logistics is our specialty.
									Please reach out if you are interested in marketing your grain or discussing
									markets.<br>
								</p>
							</div>
							<div class="row m-t-20">
								<h5>
									<u>Contact Us</u>
								</h5>
							</div>
							<div class="row ">
								<p>
									2920 26th Ave S<br>
									Fargo, ND 58103<br>
									Phone: <a href="tel:(701) 364-9344"> (701) 364-9344 </a> <br>
									Email: <a href="mailto:kevin@halocommodities.com"> kevin@halocommodities.com </a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadService } from 'src/app/shared/services/firebase/load.service';
import { Load } from 'src/app/shared/model/load.model';

@Component({
  selector: 'app-weight',
  templateUrl: './weight.component.html',
  styleUrls: ['./weight.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WeightComponent implements OnInit {

  public border_validation = false;
  public regForm: FormGroup;
  public title: string = "registration page"
  public form: any;
  public load: Load = {bol:{}};

  constructor(private route: ActivatedRoute, 
    private fb: FormBuilder,
    private loadService: LoadService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if(params['loadId']){
        this.loadService.getLoadById(params['loadId']).subscribe(data =>{
          this.load = data;
          if(!this.load.bol)
            this.load.bol = {};
        })
      }
    });
  }

  //create form
  createForm() {
    this.regForm = this.fb.group({
      weight: [null, [Validators.required]]
    });
  }

  save(form: any) {
    if (!form.valid) {
      return false;
    }
    this.loadService.updateLoad(this.load);
    return true;
  }

}

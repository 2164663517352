import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'office',
    loadChildren: () => import('../../components/office/office.module').then(m => m.OfficeModule),
  },
  {
    path: 'sample',
    loadChildren: () => import('../../components/sample/sample.module').then(m => m.SampleModule)
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
  },
  {
    path: 'producer',
    loadChildren: () => import('../../components/producer/producer.module').then(m => m.ProducerModule),
  },
  {
    path: 'contractor',
    loadChildren: () => import('../../components/contractor/contractor.module').then(m => m.ContractorModule),
  },
  {
    path: 'broker',
    loadChildren: () => import('../../components/broker/broker.module').then(m => m.BrokerModule),
  },
  {
    path: 'logistics-customer',
    loadChildren: () => import('../../components/logistics-customer/logistics-customer.module').then(m => m.LogisticsCustomerModule),
  },
  {
    path: 'work-in-progress',
    loadChildren: () => import('../../components/work-in-progress/work-in-progress.module').then(m => m.WorkInProgressModule)
  },
  {
    path: 'eggs',
    loadChildren: () => import('../../components/eggs/eggs.module').then(m => m.EggsModule)
  },
];
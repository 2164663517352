<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 footer-copyright">
      <p class="mb-0">Copyright 2022 ©</p>
    </div>
    <div class="col-md-6">
      <p class="pull-right mb-0 egg" [routerLink]="['/eggs/snake']">Hand crafted</p>
    </div>
  </div>
</div>
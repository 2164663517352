import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InjectWhoDataService {

  constructor() {
  }

  public injectWhoData(data: any) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (!data.whoData) {
      data.whoData = {
        createdByUid: user.uid,
        createdBy: user.displayName,
        createdOn: new Date(),
        updatedByUid: user.uid,
        updatedByName: user.displayName,
        updatedOn: new Date(),
      };
    } else {
      data.whoData.createdOn = data.whoData.createdOn.seconds ?
        data.whoData.createdOn :
        new Date(Date.parse(data.whoData.createdOn));
      data.whoData.updatedByUid = user.uid;
      data.whoData.updatedByName = user.displayName;
      data.whoData.updatedOn = new Date();
    }
    return data;
  }

}

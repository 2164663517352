<form [formGroup]="regForm" (ngSubmit)="save(regForm)" class="ngx-form" novalidate>
    <div class="form-group">
        <label class="control-label" for="comments">Comments:</label>
        <textarea id="comments" [(ngModel)]="load.bol.comments" type="text" formControlName="comments" class="form-control" placeholder="Comments.." autofocus ></textarea>
    </div>
    <div class="form-group f1-buttons">
        <button class="btn btn-primary btn-previous" type="button" awPreviousStep>Previous</button>
        <button class="btn btn-primary btn-next" awNextStep> Next</button>
    </div>
</form>

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ToastrService } from 'ngx-toastr';
import { CommodityRates, Rates } from 'src/app/shared/model/commodity-rates.model';
import { Contract } from 'src/app/shared/model/contract.model';
import { Load } from 'src/app/shared/model/load.model';
import { ContractService } from 'src/app/shared/services/firebase/contract.service';
import { LoadService } from 'src/app/shared/services/firebase/load.service';
import { ImageCropperComponent } from '../../image-cropper/component/image-cropper.component';
import { ImageCroppedEvent } from '../../image-cropper/interfaces';
import { take } from 'rxjs/operators';
import * as moment from 'moment';
import { User } from 'src/app/shared/model/user.model';

@Component({
  selector: 'app-bol',
  templateUrl: './bol.component.html',
  styleUrls: ['./bol.component.scss']
})
export class BolComponent implements OnInit {

  @ViewChild('ImageCropper', { static: true }) imageCropper: ImageCropperComponent;

  public regForm: FormGroup;
  public form: any;
  public load: Load = { dropOff: {} };
  public imageChangedEvent: any = '';
  public imageBase64: any = '';
  public croppedImage: any = '';
  public showCropper = false;
  public showLoader = false;
  public bolURL: string;
  public imgResultBeforeCompress: string;
  public imgResultAfterCompress: string;
  public dropOffFileMeta = '';
  public rates: CommodityRates[];
  private user: User;


  constructor(private route: ActivatedRoute,
    private fb: FormBuilder,
    private imageCompress: NgxImageCompressService,
    public toster: ToastrService,
    public router: Router,
    private loadService: LoadService,
    private contractService: ContractService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.route.params.subscribe(params => {
      if (params['loadId']) {
        this.loadService.getLoadById(params['loadId']).subscribe(data => {
          this.load = data;
          if (this.load.bol && this.load.bol.signOffName !== '') {
            //ooof what a mess, this is what I get for not understanding cloud storage when I built it
            if (!this.load.bol.url) {
              this.loadService.getBOL(this.load).then(data => {
                if (data.includes('.jpeg')) {
                  this.dropOffFileMeta = 'image';
                } else
                  this.dropOffFileMeta = 'pdf';
                this.bolURL = data;
              });
            }else{
              this.bolURL = this.load.bol.url
              if (this.load.bol.url.includes('.jpeg')) {
                this.dropOffFileMeta = 'image';
              } else
                this.dropOffFileMeta = 'pdf';
            }

            this.regForm.controls.bol.setValue(this.load.bol.signOffName);
          }

        })
      }
    });
    this.rates = new Rates().defaultRates;

  }
  //create form
  createForm() {
    this.regForm = this.fb.group({
      bol: [null, [Validators.required]]
    });
  }

  // Display dummy cropped image 
  public imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  //Display cropper on selected image
  public imageLoaded() {
    if (!this.showCropper) {
      this.rotateLeft();
    }
    this.showCropper = true;
  }

  //Select a file
  public fileChangeEvent(event) {
    this.dropOffFileMeta = '';
    this.showLoader = true;
    var pattern = /image-*/;
    if (!event.target.files[0].type.match(pattern)) {
      const tost = this.toster.info('File is uploading', 'Uploading', { disableTimeOut: true });
      tost.onShown.subscribe(_ => {
        this.loadService.uploadBOL(this.load, event.target.files[0]).then(_ => {
          this.toster.clear(tost.toastId);
          this.toster.success('File uploaded!');
        });
      })
      this.showCropper = false;
      this.showLoader = false;
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      let imageBase64 = reader.result as string;
      this.imgResultBeforeCompress = imageBase64;

      console.warn('Size in bytes was:', this.imageCompress.byteCount(imageBase64));
      this.imageCompress.compressFile(imageBase64, 1, 50, 50).then(
        result => {
          this.imgResultAfterCompress = result;
          this.imageBase64 = result;
          this.showLoader = false;
          console.warn('Size in bytes is now:', this.imageCompress.byteCount(result));
        }
      ).catch(err => console.error(err));
    }
    this.showLoader = false;
  }

  //Rotate an image to left
  public rotateLeft() {
    this.imageCropper.rotateLeft();
  }
  //Rotate an image to right
  public rotateRight() {
    this.imageCropper.rotateRight();
  }

  //Flip an Image Horizontal
  public flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }

  //Flip an Image Vertical
  public flipVertical() {
    this.imageCropper.flipVertical();
  }

  savePicture() {
    const tost = this.toster.info('Photo is uploading', 'Uploading', { disableTimeOut: true });
    tost.onShown.subscribe(_ => {
      this.loadService.uploadBOL(this.load, this.croppedImage).then(_ => {
        this.toster.clear(tost.toastId);
        this.toster.success('Photo uploaded!');
        this.loadService.getBOL(this.load).then(data => {
          this.bolURL = data;
        });
      });
    })
    this.showCropper = false;
  }

  save() {
    if (this.load.status === 'Complete')
      this.router.navigate(['/office/loads']);
    else {
      this.router.navigate(['/contractor/my-loads']);
      this.load.dropOff.droppedOffBy = this.user.displayName;
      this.load.dropOff.droppedOffOn = moment().format('llll');
    }

    this.load.status = 'Complete';
    if (this.load.contractId !== '') {
      this.contractService.getContract(this.load.contractId).pipe(take(1)).subscribe(data => {

        let contract = data as Contract;

        //create allocation data on Load
        this.load.allocatedContracts = [{
          contractId: this.load.contractId,
          contractNo: contract.contractNo,
          qty: parseInt(this.load.dropOff.qty)
        }];

        this.load.allocatedContractIds = [
          this.load.contractId
        ];

        this.loadService.updateLoad(this.load).then(_ => {
          this.toster.success('Drop Off Completed!');

          let rate = Object.create(this.rates.find(rate => contract.commodity === rate.name));
          //if it's not per BU we assume Hundredweight which is 100lb per commodity
          if (contract.qtyUom === 'CWT')
            rate.rate = 100;

          contract.grossWeight = rate.rate * contract.qty;

          this.getContractAllocations(contract).then((contractAllocation: number) => {
            contract.precentCompleted = Math.round((contractAllocation / (parseInt((contract.qty + '').replace(/[^0-9.]/g, '')) * rate.rate)) * 100);

            if (contract.precentCompleted >= 95)
              contract.status = "Complete";

            this.contractService.updateContract(contract).catch(data => {
              this.toster.error('There was an error updating the parent contract')
            });
          });

        });
      });
    } else {
      this.loadService.updateLoad(this.load).then(_ => {
        this.toster.success('Drop Off Completed!');
      });
    }

  }

  private getContractAllocations(contract: Contract) {
    return new Promise(resolve => {
      this.contractService.getAllocatedLoads(contract).subscribe(data => {
        const allocatedLoads = data.map(data => {
          let load = data.payload.doc.data() as Load;
          //this makes it so the only allocations for the load are the ones for the selected contract
          load.allocatedContracts = [load.allocatedContracts.find(data => {
            return data.contractId === contract.uid;
          })];

          return load;
        });
        let qtySum = 0;

        allocatedLoads.forEach(load => {
          qtySum += parseInt(load.allocatedContracts[0].qty + '');
        })
        resolve(qtySum);
      }, e => {
        resolve(0)
      })
    });
  }


}

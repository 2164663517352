import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Load } from 'src/app/shared/model/load.model';
import { LoadService } from 'src/app/shared/services/firebase/load.service';
import { ImageCropperComponent } from '../../image-cropper/component/image-cropper.component';
import { ImageCroppedEvent } from '../../image-cropper/interfaces';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-scale-pic',
  templateUrl: './scale-pic.component.html',
  styleUrls: ['./scale-pic.component.scss']
})
export class ScalePicComponent implements OnInit {

  @ViewChild('ImageCropper', { static: true }) imageCropper: ImageCropperComponent;

  public regForm: FormGroup;
  public form: any;
  public imageChangedEvent: any = '';
  public imageBase64: any = '';
  public croppedImage: any = '';
  public showCropper = false;
  public showLoader = false;
  public imgResultBeforeCompress: string;
  public imgResultAfterCompress: string;
  public dropOffFileMeta = '';

  public load: Load = { bol: {} };

  constructor(private route: ActivatedRoute,
    private imageCompress: NgxImageCompressService,
    private fb: FormBuilder,
    public toster: ToastrService,
    private loadService: LoadService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['loadId']) {
        this.loadService.getLoadById(params['loadId']).subscribe(data => {
          this.load = data;
          if (this.load.dropOff && this.load.dropOff.ticketPhotos.length > 0) {
            if (this.load.dropOff.ticketPhotos[0].url.includes('.jpeg')) {
              this.dropOffFileMeta = 'image';
            } else
              this.dropOffFileMeta = 'pdf';
            this.regForm.controls.img.setValue(this.load.dropOff.ticketPhotos[0].url);
          }
        })
      }
    });
  }

  //create form
  createForm() {
    this.regForm = this.fb.group({
      img: [null, [Validators.required]]
    });
  }

  save(form: any) {
    if (!form.valid) {
      return false;
    }
    this.loadService.updateLoad(this.load);
    return true;
  }

  // Display dummy cropped image 
  public imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  //Display cropper on selected image
  public imageLoaded() {
    if (!this.showCropper) {
      this.rotateLeft();
    }
    this.showCropper = true;
  }

  //Select a file
  public fileChangeEvent(event) {
    this.dropOffFileMeta = '';
    this.showLoader = true;
    var pattern = /image-*/;
    if (!event.target.files[0].type.match(pattern)) {
      const tost = this.toster.info('File is uploading', 'Uploading', { disableTimeOut: true });
      tost.onShown.subscribe(_ => {
        this.loadService.uploadDropOffTicket(this.load, event.target.files[0]).then(_ => {
          this.toster.clear(tost.toastId);
          this.toster.success('File uploaded!');
        });
      })
      this.showCropper = false;
      this.showLoader = false;
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      let imageBase64 = reader.result as string;
      this.imgResultBeforeCompress = imageBase64;

      console.warn('Size in bytes was:', this.imageCompress.byteCount(imageBase64));
      this.imageCompress.compressFile(imageBase64, 1, 50, 50).then(
        result => {
          this.imgResultAfterCompress = result;
          this.imageBase64 = result;
          this.showLoader = false;
          console.warn('Size in bytes is now:', this.imageCompress.byteCount(result));
        }
      ).catch(err => console.log(err));
    }

  }

  //Rotate an image to left
  public rotateLeft() {
    this.imageCropper.rotateLeft();
  }
  //Rotate an image to right
  public rotateRight() {
    this.imageCropper.rotateRight();
  }

  //Flip an Image Horizontal
  public flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }

  //Flip an Image Vertical
  public flipVertical() {
    this.imageCropper.flipVertical();
  }

  savePicture() {
    const tost = this.toster.info('Photo is uploading', 'Uploading', { disableTimeOut: true });
    tost.onShown.subscribe(_ => {
      this.loadService.uploadDropOffTicket(this.load, this.croppedImage).then(_ => {
        this.toster.clear(tost.toastId);
        this.toster.success('Photo uploaded!');
      });
    })
    this.showCropper = false;
  }

}

<form [formGroup]="regForm" (ngSubmit)="save(regForm)" class="ngx-form" novalidate>
    <div class="form-group">
        <label class="control-label" for="qty">Qty (lbs):</label>
        <div class="input-group">
            <input id="qty" type="text" [(ngModel)]="load.dropOff.qty" (input)="inputValidator($event)" formControlName="qty" class="form-control"
                placeholder="Enter qty in lbs..." autofocus>
            <div class="input-group-append">
                <span class="input-group-text">lbs</span>
            </div>
        </div>
        <div *ngIf="regForm.controls['qty'].invalid && (regForm.controls['qty'].dirty || regForm.controls['qty'].touched)"
            class="alert" >
            <div *ngIf="regForm.controls['qty'].errors.required">
                Qty is required.
            </div>
        </div>
    </div>
    <div class="form-group f1-buttons">
        <button class="btn btn-primary btn-previous" type="button" awPreviousStep>Previous</button>
        <button class="btn btn-success btn-next" *ngIf='!load?.isDestinationEBOL' [disabled]="!regForm.valid" awNextStep>Next</button>
        <button class="btn btn-success btn-next" *ngIf='load?.isDestinationEBOL' (click)="complete()" awNextStep> Complete</button>
    </div>
</form>
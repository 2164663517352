import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Load } from '../../model/load.model';
import { leftJoin } from '../../firebase-joins/left-join';
import { AngularFireFunctions } from '@angular/fire/functions';
import * as moment from 'moment';
import { InjectWhoDataService } from '../inject-who-data.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadService {

  constructor(public db: AngularFirestore,
    private storage: AngularFireStorage,
    private injectWhoData: InjectWhoDataService,
    private fns: AngularFireFunctions) { }

  /**
   * Upload new photo for a load
   * @param load 
   * @param photoEvent 
   */
  uploadLoadPhoto(load: Load, photoEvent) {
    load = this.injectWhoData.injectWhoData(load);
    var photoObj = { uid: this.db.createId(), url: '', alt: '' };

    let file = photoEvent.target.files[0];
    if (file.type.includes('image')) {
      photoObj.alt = '.jpeg';
    }
    else {
      file = file;
      photoObj.alt = '.pdf';
    }

    const filePath = `LoadPictures/${photoObj.uid}${photoObj.alt}`;
    const fileRef = this.storage.ref(filePath);

    return this.storage.upload(`LoadPictures/${photoObj.uid}${photoObj.alt}`, file).then(_ => {
      fileRef.updateMetadata({ contentType: 'application/octet-stream' });
      fileRef.getDownloadURL().subscribe(url => {
        if (!load.photos)
          load.photos = [];

        photoObj.url = url;
        load.photos = [...load.photos, photoObj];

        this.db.collection('loads').doc(load.uid).set(load);
      })
    });
  }

  /**
 * Attach files to contracts
 * @param event 
 * @param contract 
 */
  attachFile(event, load) {
    load = this.injectWhoData.injectWhoData(load);
    var fileObj = { uid: this.db.createId(), url: '', name: '', alt: '' };

    let file = event.target.files[0];
    if (file.type.includes('image')) {
      fileObj.alt = '.jpeg';
    }
    else {
      file = file;
      fileObj.alt = '.pdf';
    }

    const fileName = event.target.files[0].name;
    const filePath = `LoadAttachments/${fileObj.uid}${fileObj.alt}`;
    const fileRef = this.storage.ref(filePath);

    return this.storage.upload(`LoadAttachments/${fileObj.uid}${fileObj.alt}`, file).then(_ => {
      fileRef.updateMetadata({ contentType: 'application/octet-stream' });
      fileRef.getDownloadURL().subscribe(url => {
        if (!load.attachmentFiles)
          load.attachmentFiles = [];

        fileObj.url = url;
        fileObj.name = fileName;
        load.attachmentFiles = [...load.attachmentFiles, fileObj];

        this.db.collection('loads').doc(load.uid).set(load);
      })
    });

  }

  /**
   * delete attachemnt from storage
   * @param attachment 
   */
  deleteAttachment(attachment) {
    this.storage.ref(`LoadAttachments/${attachment.uid}`).delete();
  }

  /**
   * 
   * @param load 
   * @param photoEvent 
   */
  uploadDropOffTicket(load: Load, newFile) {
    load = this.injectWhoData.injectWhoData(load);
    var photoObj = { uid: this.db.createId(), url: '', alt: '' };

    let file;
    if (!newFile.type) {
      file = this.b64toBlob(newFile)
      photoObj.alt = '.jpeg';
    }
    else {
      file = newFile;
      photoObj.alt = '.pdf';
    }

    const filePath = `LoadPictures/${photoObj.uid}${photoObj.alt}`;
    const fileRef = this.storage.ref(filePath);

    return this.storage.upload(`LoadPictures/${photoObj.uid}${photoObj.alt}`, file).then(_ => {
      fileRef.updateMetadata({ contentType: 'application/octet-stream' });
      fileRef.getDownloadURL().subscribe(url => {
        if (!load.dropOff)
          load.dropOff = {};

        photoObj.url = url;
        load.dropOff.ticketPhotos = [photoObj];

        this.db.collection('loads').doc(load.uid).set(load);
      })
    });
  }

  /**
   * 
   * @param load 
   * @param photo 
   */
  uploadBOL(load: Load, photo) {
    load = this.injectWhoData.injectWhoData(load);
    var photoObj = { uid: this.db.createId(), url: '', alt: '' };
    let file;
    if (!photo.type) {
      file = this.b64toBlob(photo)
      photoObj.alt = '.jpeg';
    }
    else {
      file = photo;
      photoObj.alt = '.pdf';
    }

    const filePath = `BOLs/${load.uid}${photoObj.alt}`;
    const fileRef = this.storage.ref(filePath);

    return this.storage.upload(`BOLs/${load.uid}${photoObj.alt}`, file).then(_ => {
      fileRef.updateMetadata({ contentType: 'application/octet-stream' });
      fileRef.getDownloadURL().subscribe(url => {
        load.bol = {
          loadedBy: load.contractorName ? load.contractorName : load.brokerName,
          signOffDate: moment().format('L'),
          signOffName: load.contractorName ? load.contractorName : load.brokerName,
          url: url
        };

        this.db.collection('loads').doc(load.uid).set(load);
      })
    });
  }

  /**
   * 
   * @param load 
   * @param photoId
   */
  removeLoadPhoto(photoId) {
    return this.storage.ref(`LoadPictures/${photoId}`).delete().subscribe(data => {
    }, error => { console.log(error) });
  }

  /**
   * Create a new load
   * @param load 
   */
  createLoad(load: Load) {
    load = this.injectWhoData.injectWhoData(load);
    //get UID for contract and file
    load.uid = this.db.createId();
    return this.db.collection('loads').doc(load.uid).set(load);
  }

  /**
   * Update existing load
   * @param load 
   */
  updateLoad(load: Load) {
    load = this.injectWhoData.injectWhoData(load);
    return this.db.collection('loads').doc(load.uid).update(load);
  }

  /**
   * Get's all of a specified contractor's loads
   * @param userId 
   */
  getLoadsByContractorUser(userId: string) {
    return this.db.collection('loads', ref => ref.where('contractorUserId', '==', userId).orderBy('loadNo', 'asc')).valueChanges()
    .pipe(
      leftJoin(this.db, 'contractId', 'contracts')
    );
  }

  /**
 * Get's all of a specified broker's loads
 * @param userId 
 */
  getLoadsByBrokerUser(userId: string) {
    return this.db.collection('loads', ref => ref.where('brokerUserId', '==', userId).orderBy('loadNo', 'asc')).snapshotChanges();
  }

  /**
   * Get Loads by producerID where load in status 'Assigned', 'Completed', 'Accepted', 'In Transit'
   * @param producerId 
   */
  getLoadsByProducerFiltedByStatus(producerId: string) {
    return this.db.collection('loads', ref => ref.where('producerId', '==', producerId).orderBy('loadNo', 'asc')
      .where('status', 'in', ['Assigned', 'Completed', 'Accepted', 'In Transit'])).snapshotChanges();
  }

  /**
   * Get Loads by logisticsCustomerID where load in status 'Assigned', 'Completed', 'Accepted', 'In Transit'
   * @param logisticsCustomerId 
   */
  getLoadsByLogisticsCustomerFiltedByStatus(logisticsCustomerId: string) {
    return this.db.collection('loads', ref => ref.where('logisticsCustomerId', '==', logisticsCustomerId).orderBy('loadNo', 'asc')
      .where('status', 'in', ['Assigned', 'Completed', 'Accepted', 'In Transit'])).snapshotChanges();
  }

  /**
   * Get Loads by Producer
   * @param producerId 
   */
  getLoadsByProducer(producerId: string) {
    return this.db.collection('loads', ref => ref.where('producerId', '==', producerId).orderBy('loadNo', 'asc')).snapshotChanges();
  }

  /**
   * Get Loads by LogisticsCustomer
   * @param logisticsCustomerId 
   */
  getLoadsByLogisticsCustomer(logisticsCustomerId: string) {
    return this.db.collection('loads', ref => ref.where('logisticsCustomerId', '==', logisticsCustomerId).orderBy('loadNo', 'asc')).snapshotChanges();
  }

   /**
   * Get Loads by Contractor
   * @param contractorId 
   */
   getLoadsByContractor(contractorId: string) {
    return this.db.collection('loads', ref => ref.where('contractorId', '==', contractorId).orderBy('loadNo', 'asc')).snapshotChanges();
  }

  /**
   * Get Loads by Producer
   * @param producerId 
   */
  getLoadsByProducerWContract(producerId: string) {
    return this.db.collection('loads', ref => ref.where('producerId', '==', producerId).orderBy('loadNo', 'asc')).valueChanges()
      .pipe(
        leftJoin(this.db, 'contractId', 'contracts')
      );
  }

  /**
   * Get Loads by LogisticsCustomerId
   * @param logisticsCustomerId 
   */
  getLoadsByLogisticsCustomerWContract(logisticsCustomerId: string) {
    return this.db.collection('loads', ref => ref.where('logisticsCustomerId', '==', logisticsCustomerId).orderBy('loadNo', 'asc')).valueChanges()
      .pipe(
        leftJoin(this.db, 'contractId', 'contracts')
      );
  }

  /**
   * Get loads BOL file
   * @param loadId 
   */
  getBOL(load: Load) {
    return this.storage.storage.ref(`BOLs/${load.uid}${load.isDestinationEBOL ? '.pdf' : '.jpeg'}`).getDownloadURL();
  }

  /**
   * Get loads meta data
   * @param loadDropOffURL 
   */
  getDropOffTicketMeta(loadDropOffURL: string) {
    return this.storage.storage.refFromURL(loadDropOffURL).getMetadata();
  }

  /**
   * 
   * @param url 
   * @returns 
   */
  getFilebyURL(url: string) {
    return this.storage.storage.refFromURL(url).getDownloadURL();
  }

  /**
   * Get's the next load number
   */
  getNewLoadNumber() {
    console.log('getNewLoadNumber')
    return this.db.collection('loads', ref => ref.orderBy('loadNo', 'desc').limit(1)).get({ source: 'server' }).pipe(
      map(actions => {
        const data = [];
        actions.forEach(a => {
          const item = a.data();
          data.push(item);
        });
        return data;
      })
    );
  }

  /**
   * Deletes load
   * @param load 
   */
  deleteLoad(load: Load) {
    return this.db.collection('loads').doc(load.uid).delete();
  }

  /**
   * Get all loads with their associated contracts
   */
  getAllLoads(statuses: string[] = ['New', 'Assigned', 'In Transit', 'Accepted', 'Complete', 'Rejected','Archived']) {
    return this.db.collection('loads', ref => ref.where('status', 'in', statuses)).valueChanges().pipe(
      leftJoin(this.db, 'contractId', 'contracts')
    ).pipe(
      leftJoin(this.db, 'destinationId', 'destinations')
    );
  }

  /**
   * Get all loads with their associated contracts
   */
  getAllLoadsByProducer(producerId: string) {
    return this.db.collection('loads', ref => ref.where('producerId', '==', producerId)).valueChanges().pipe(
      leftJoin(this.db, 'contractId', 'contracts')
    ).pipe(
      leftJoin(this.db, 'destinationId', 'destinations')
    );
  }

  /**
   * Get all loads with their associated contracts by logistics customer
   */
  getAllLoadsByLogisticsCustomer(logisticsCustomerId: string) {
    return this.db.collection('loads', ref => ref.where('logisticsCustomerId', '==', logisticsCustomerId)).valueChanges().pipe(
      leftJoin(this.db, 'contractId', 'contracts')
    ).pipe(
      leftJoin(this.db, 'destinationId', 'destinations')
    );
  }

  /**
   * Get all loads with their associated contracts
   */
  getAllLoadsByContractor(contractorId: string) {
    return this.db.collection('loads', ref => ref.where('contractorUserId', '==', contractorId)).valueChanges().pipe(
      leftJoin(this.db, 'contractId', 'contracts')
    ).pipe(
      leftJoin(this.db, 'destinationId', 'destinations')
    );
  }

  /**
   * Get load by id
   * @param loadId 
   */
  getLoadById(loadId: string) {
    return this.db.collection('loads').doc(loadId).valueChanges();
  }

  /**
   * Get loads assigned to contract
   * @param contractUid 
   */
  getLoads(contractUid: string) {
    return this.db.collection('loads', ref => ref.where('contractId', '==', contractUid).orderBy('loadNo', 'asc')).valueChanges();
  }

  getLoadsByDateRange(startDate: Date, endDate: Date) {
    return this.db.collection('loads', ref => ref.where('whoData.createdOn', '>=', startDate).where('whoData.createdOn', '<=', endDate)).snapshotChanges();
  }

  /**
   * Bulk update loads 
   * @param loads 
   * @returns promis of batch commit
   */
  bulkUpdateLoads(loads: Load[]) {
    let batch = this.db.firestore.batch();
    loads.forEach((load, index) => {
      load = this.injectWhoData.injectWhoData(load);
      let docRef = this.db.collection('loads').doc(load.uid).ref;
      batch.update(docRef, load);
      if ((index + 1) % 500 === 0) {
        batch.commit();
        batch = this.db.firestore.batch();
      }
    });
    return batch.commit();
  }

  /**
     * Conver bit map to BLOB for image cropper
     * @param dataURI 
  */
  b64toBlob(dataURI) {

    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }


}


import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { AuthService } from '../../services/firebase/auth.service';
import { UserService } from '../../services/firebase/user.service';
import { User } from '../../model/user.model';
import { EventEmitterService } from '../../services/event-emitter.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public menuItems: Menu[];
  public items: Menu[];
  public openNav: boolean = false;
  public right_sidebar: boolean = false;
  public text: string;
  public user: User;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(public navServices: NavService,
    public authService: AuthService,
    private userService: UserService,
    private eventEmitterService: EventEmitterService,
    private deviceService: DeviceDetectorService) { }

  ngOnInit() {
    //get menu items
    this.navServices.items.subscribe(menuItems => {
      this.items = menuItems
    });
    //get user data
    let user = JSON.parse(localStorage.getItem('user'));
    this.userService.getUser(user.uid).subscribe(data => {
      this.user = data as User;
      if (this.user.photoURL === 'assets/images/dashboard/user.png')
        this.user.photoURL = `https://ui-avatars.com/api/?background=random&name=${this.user.displayName.replace(' ', '+')}`;
    });
    //setup event emitters for the open/close 
    if (this.eventEmitterService.subsVar == undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
        invokeSideBar.subscribe((name: string) => {
          if (!this.deviceService.isDesktop())
            this.collapseSidebar();
        });
    }
    // if (!this.deviceService.isDesktop())
    //   this.collapseSidebar();
  }

  collapseSidebar() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
    if (!this.deviceService.isDesktop())
      this.openNav = false;

  }

  openMobileNav() {
    this.openNav = !this.openNav;
    if (!this.deviceService.isDesktop())
      this.navServices.collapseSidebar = true;
  }

}

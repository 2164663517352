import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Load } from 'src/app/shared/model/load.model';
import { LoadService } from 'src/app/shared/services/firebase/load.service';

@Component({
  selector: 'app-nav-bar-dropoff',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavBarDropoffComponent implements OnInit {

  public load: Load;

  constructor(private route: ActivatedRoute,
    private loadService: LoadService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['loadId']) {
        this.loadService.getLoadById(params['loadId']).subscribe(data => {
          this.load = data;
        })
      }
    });
  }

  setFocus(id: string) {
    setTimeout(() => {
      var inputEl = document.getElementById(id);
      if (inputEl) {
        document.getElementById(id).focus();
      }
    }, 0);
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../shared/services/firebase/auth.service';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ transform: 'translateX(-100%)' }),
            animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
          ]
        )
      ]
    ),
    trigger(
      'outInAnimation',
      [
        transition(
          ':enter',
          [
            style({ transform: 'translateX(100%)' }),
            animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
          ]
        )
      ]
    )
  ],
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public newUser = false;
  public user: firebase.User;
  public loginForm: FormGroup;
  public errorMessage: any;
  public restPassword: boolean = false;
  public contactView: boolean = false;

  constructor(public authService: AuthService,
    private fb: FormBuilder,
    private router: Router) {
    this.loginForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit() {

  }

  forgotPassword() {
    this.restPassword = !this.restPassword;
  }

  newUserSelected() {
    this.newUser = !this.newUser;
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required]
    }, { 
      validator: this.confirmedValidator('password', 'confirmPassword')
    });

  }

  cancelForm() {
    this.restPassword = false;
    this.newUser = false;
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  contactUs() {
    this.contactView = !this.contactView;
  }

  // Simple Login
  login() {

    if (this.restPassword) {
      this.authService.ForgotPassword(this.loginForm.value['email']);
      this.restPassword = false;
      return;
    }

    this.loginForm.markAllAsTouched();
    if (!this.loginForm.valid)
      return;

    if (this.newUser) {
      this.authService.SignUp(this.loginForm.value['email'], this.loginForm.value['password']);
      this.loginForm.reset();
      this.cancelForm();
      return;
    }

    this.authService.SignIn(this.loginForm.value['email'], this.loginForm.value['password']);

  }

  confirmedValidator(controlName: string, matchingControlName: string) {

    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }

    }
  }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//import * as firebase_env from './environment.prod.json';

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCwYwUxq7d38e3WWhqFMSyuE9XcAy69xOk",
    authDomain: "halo-4c6c1.firebaseapp.com",
    databaseURL: "https://halo-4c6c1.firebaseio.com",
    projectId: "halo-4c6c1",
    storageBucket: "halo-4c6c1.appspot.com",
    messagingSenderId: "51711974105",
    appId: "1:51711974105:web:0beedeca15ad992f87bcc8",
    measurementId: "G-GF2YL5JSQF"
}
};





// apiKey: "AIzaSyALZNOVDaXFQRWKXSl5gtmbxxjn5t2O96s",
// authDomain: "halo-pp.firebaseapp.com",
// projectId: "halo-pp",
// storageBucket: "halo-pp.appspot.com",
// messagingSenderId: "482082555003",
// appId: "1:482082555003:web:53a3627b0387aba2780ede",
// measurementId: "G-QG5R52ZMH1"

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

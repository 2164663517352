import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Load } from 'src/app/shared/model/load.model';
import { LoadService } from 'src/app/shared/services/firebase/load.service';
import * as moment from 'moment';

@Component({
  selector: 'app-sign-off',
  templateUrl: './sign-off.component.html',
  styleUrls: ['./sign-off.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignOffComponent implements OnInit {

  public border_validation = false;
  public regForm: FormGroup;
  public title: string = "registration page"
  public form: any;
  public load: Load = {bol:{}};

  constructor(private route: ActivatedRoute, 
    private fb: FormBuilder,
    private loadService: LoadService,
    public toster: ToastrService,
    public router: Router) {
    this.createForm();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['loadId']) {
        this.loadService.getLoadById(params['loadId']).subscribe(data => {
          this.load = data;
          if (!this.load.bol)
            this.load.bol = {}
        })
      }
    });
  }

  //create form
  createForm() {
    this.regForm = this.fb.group({
      firstName: [null, [Validators.required]]
    });
  }

  save(form: any) {
    if (!form.valid) {  
      return false;
    }

    this.load.bol.signOffDate = moment().format('L');
    this.load.status = 'In Transit';
    
    this.loadService.updateLoad(this.load).then(_=> {
      this.toster.success('BOL Completed!');
    });

    this.router.navigate(['/contractor/my-loads']);
    return true;
  }

}

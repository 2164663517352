import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Load } from 'src/app/shared/model/load.model';
import { LoadService } from 'src/app/shared/services/firebase/load.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommentsComponent implements OnInit {

  public border_validation = false;
  public regForm: FormGroup;
  public title: string = ""
  public form: any;
  public load: Load = { bol: {} };

  constructor(private route: ActivatedRoute,
    private fb: FormBuilder,
    private loadService: LoadService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['loadId']) {
        this.loadService.getLoadById(params['loadId']).subscribe(data => {
          this.load = data;
          if (!this.load.bol)
            this.load.bol = {};
        })
      }
    });
  }

  //create form
  createForm() {
    this.regForm = this.fb.group({
      comments: [null, []]
    });
  }

  save(form: any) {
    if (!form.valid) {
      return false;
    }
    this.loadService.updateLoad(this.load);
    return true;
  }

}

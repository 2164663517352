import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	roles?: string[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor() {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			title: 'Office', icon: 'book-open', type: 'sub', badgeType: 'primary', active: true, roles: ['admin', 'halo'], children:
				[
					{
						title: 'Producer Contracts', type: 'link', active: true, roles: ['admin', 'halo'], path: '/office/producer-contracts'
					},
					{
						title: 'Logistics Contracts', type: 'link', active: true, roles: ['admin', 'halo'], path: '/office/logistics-contracts'
					},
					{
						title: 'Producers', type: 'link', active: true, path: '/office/producers'
					},
					{
						title: 'Logistics Brokers', type: 'link', active: true, path: '/office/brokers'
					},
					{
						title: 'Logistics Contractors', type: 'link', active: true, path: '/office/logistics-contractors'
					},
					{
						title: 'Logistics Customers', type: 'link', active: true, path: '/office/logistics-customers'
					},
					{
						title: 'Load Management', type: 'sub', active: true, children:
							[
								{
									title: 'Loads', type: 'link', active: true, path: '/office/loads'
								},
								{
									title: 'Destinations', type: 'link', active: true, path: '/office/destinations'
								},
								{
									title: 'Pickup Locations', type: 'link', active: true, path: '/office/pickup-locations'
								},
								{
									title: 'Bill To', type: 'link', active: true, path: '/office/bill-tos'
								}
							]
					},
					{
						title: 'Reports', icon: 'file-text', type: 'sub', badgeType: 'primary', active: true, children:
							[
								{
									title: 'Load Summary', type: 'link', active: true, path: '/office/load-summary'
								},
								{
									title: 'Load Attachments', type: 'link', active: true, path: '/office/load-attachments'
								},
								{
									title: 'Contract Summary', type: 'link', active: true, path: '/office/contract-summary'
								}
							]
					}
				]
		},
		{
			title: 'Producer', icon: 'sun', type: 'sub', active: true, roles: ['admin', 'producer'], children:
				[
					{
						title: 'My Contracts', type: 'link', active: true, path: '/producer/my-contracts'
					},
					{
						title: 'My Shipments', type: 'link', active: true, path: '/producer/my-shipments'
					},
					{
						title: 'Account Settings', type: 'sub', active: true, children:
							[
								{
									title: 'My Account', type: 'link', active: true, path: '/producer/my-account'
								},
								{
									title: 'My Profile', type: 'link', active: true, path: '/users/edit'
								}
							]
					},
					{
						title: 'Reports', icon: 'file-text', type: 'sub', active: true, children:
							[
								{
									title: 'Contract Summary', type: 'link', active: true, path: '/producer/contract-summary'
								},
								{
									title: 'Shipment Summary', type: 'link', active: true, path: '/producer/shipment-summary'
								}
							]
					}
				]
		},
		{
			title: 'Logistics Broker', icon: 'users', type: 'sub', active: true, roles: ['admin', 'broker'], children:
				[
					{
						title: 'My Loads', type: 'link', active: true, path: '/broker/my-loads'
					},
					{
						title: 'Account Settings', type: 'sub', active: true, children:
							[
								{
									title: 'My Account', type: 'link', active: true, path: '/broker/my-account'
								},
								{
									title: 'My Profile', type: 'link', active: true, path: '/users/edit'
								}
							]

					},
					{
						title: 'Reports', icon: 'file-text', type: 'sub', active: true, children:
							[
								{
									title: 'Load Summary', type: 'link', active: true, path: '/broker/load-summary'
								}
							]
					}
				]
		},
		{
			title: 'Logistics', icon: 'truck', type: 'sub', active: true, roles: ['admin', 'logistics'], children:
				[
					{
						title: 'My Loads', type: 'link', active: true, path: '/contractor/my-loads'
					},
					{
						title: 'Account Settings', type: 'sub', active: true, children:
							[
								{
									title: 'My Account', type: 'link', active: true, path: '/contractor/my-account'
								},
								{
									title: 'My Profile', type: 'link', active: true, path: '/users/edit'
								}
							]

					},
					{
						title: 'Reports', icon: 'file-text', type: 'sub', active: true, children:
							[
								{
									title: 'Load Summary', type: 'link', active: true, path: '/contractor/load-summary'
								}
							]
					}
				]
		},
		{
			title: 'Logistics Customer', icon: 'globe', type: 'sub', active: true, roles: ['admin', 'logisticscustomer'], children:
				[
					{
						title: 'My Contracts', type: 'link', active: true, path: '/logistics-customer/my-contracts'
					},
					{
						title: 'My Loads', type: 'link', active: true, path: '/logistics-customer/my-loads'
					},
					{
						title: 'Account Settings', type: 'sub', active: true, children:
							[
								{
									title: 'My Account', type: 'link', active: true, path: '/logistics-customer/my-account'
								},
								{
									title: 'My Profile', type: 'link', active: true, path: '/users/edit'
								}
							]
					},
					{
						title: 'Reports', icon: 'file-text', type: 'sub', active: true, children:
							[
								{
									title: 'Contract Summary', type: 'link', active: true, path: '/logistics-customer/contract-summary'
								},
								{
									title: 'Load Summary', type: 'link', active: true, path: '/logistics-customer/load-summary'
								}
							]
					}
				]
		},
		{
			title: 'Administrator', icon: 'settings', type: 'sub', active: true, roles: ['admin', 'halo'], children:
				[
					{
						title: 'Manage Users', type: 'link', active: false, path: '/users/display-users'
					},
					{
						title: 'Add User', type: 'link', active: false, path: '/users/create-user'
					},
					{
						title: 'Notification Manager', type: 'sub', active: true, children: 
						[
							{
								title: 'Brokers Notifications', type: 'link', active: false, path: '/office/notification-manager'
							},
							{
								title: 'AdHoc Notifications', type: 'link', active: false, path: '/office/adhoc-notifications'
							}
						]
					},
				]
		}
	]
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}

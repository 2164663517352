import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription'; 

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {
  
  invokeSideBar = new EventEmitter();    
  subsVar: Subscription;  

  constructor() { }

  toggleSideBar() : void{
    this.invokeSideBar.emit();
  }
}
